import "./Dash.css"
import React, {useState, useEffect} from "react";
import Profile from "./Profile";
import Settings from "./Settings";
import Residents from './Residents';
import Residences from './Residences';
import {useUserData} from "../Contexts/UserDataContext"
import Accounts from './Accounts';
import PayDues from "./PayDues";
import Home from './Home';
import SendAlerts from './SendAlerts'
import EditCalendar from './EditCalendar'
import KeyFobs from "./KeyFobs"
import { useNavigate } from 'react-router-dom';
import Sidebar from "./Sidebar";

const DashHandler = () => {
  const navigate = useNavigate()
  const {userData, loading, setUID, setUserData} = useUserData()
  const [chosenPage, setChosenPage] = useState("account")
  const [chosenCompany,setChosenCompany] = useState("")
  const [sidebarStatus,setSidebarStatus] = useState(true)
  
  useEffect(() => {
    if (!userData && !loading) {
      navigate("/login");
    }

  }, [userData, navigate]);





  const DashboardSplitter = () => {
    switch(chosenPage){
      case "profile":
        return <Profile sidebarStatus={sidebarStatus}/>
      case "payDues":
        return <PayDues sidebarStatus={sidebarStatus}/>  
      case "residences":
        return <Residences sidebarStatus={sidebarStatus}/>
      case "residents":
        return <Residents sidebarStatus={sidebarStatus}/>
      case "accounts":
        return <Accounts sidebarStatus={sidebarStatus}/>
      case "keyFobs":
        return <KeyFobs sidebarStatus={sidebarStatus}/>
      case "editCalendar":
        return <EditCalendar sidebarStatus={sidebarStatus}/>
      case "sendAlerts":
        return <SendAlerts sidebarStatus={sidebarStatus}/>
      default:
        return <Profile sidebarStatus={sidebarStatus}/>
    }
  }


  
  return(
    <div className="dash-app">
      <Sidebar 
        setUserData={setUserData} 
        setUID={setUID} 
        chosenPage={chosenPage} 
        setChosenPage={setChosenPage} 
        sidebarStatus={sidebarStatus} 
        setSidebarStatus={setSidebarStatus} 
        setChosenCompany={setChosenCompany} 
        chosenCompany={chosenCompany}
      />
      <div style={{width:5}} />
      <div className="dash-content">
        <DashboardSplitter />
      </div>
    </div>
  )
}

export default DashHandler;