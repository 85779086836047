import {BsSearch,BsPlusCircle,BsGearFill,BsTrashFill,BsBanFill,BsCheckSquareFill } from 'react-icons/bs'
import {useDashData} from '../Contexts/DashboardDataContext';
import {useState, useRef} from 'react'
import {doc, updateDoc} from 'firebase/firestore';
import { FBDataBase } from '../Firebase/firebase';
import PopUp from './PopUp';

const DashboardResidents = (sidebarStatus) => {

    const [popUpActive, setPopUpActive] = useState(false)
    const [denyPopUpActive, setDenyPopUpActive] = useState(false)
    const relevantUser = useRef();
    
    const {residentsData} = useDashData();
    const pendingResidents = residentsData.filter(user => (!user.approved && user.pending));
    const approvedResidents = residentsData.filter(user => user.approved);


    const modifyUser = (userID) => {

    }

    const removeUserConfirm = (userID) => {
      setPopUpActive(true)
      relevantUser.current = userID;

    }

    const denyUserConfirm = (userID) => {
      setDenyPopUpActive(true)
      relevantUser.current = userID;
    }

    const removeUser = async () => {
      try {
        const userRef = doc(FBDataBase, "users", relevantUser.current);
        await updateDoc(userRef, { approved: false, pending: false });
        console.log(`User ${relevantUser.current} removed successfully.`);
      } catch (error) {
          console.error("Error removing user:", error);
      }
    }

    const acceptUser = async (userID) => {
      try {
          const userRef = doc(FBDataBase, "users", userID);
          await updateDoc(userRef, { approved: true, pending: false });
          console.log(`User ${userID} approved successfully.`);
      } catch (error) {
          console.error("Error approving user:", error);
      }
  };

  // Deny User (Set approved to false)
  const denyUser = async () => {
      try {
          const userRef = doc(FBDataBase, "users", relevantUser.current);
          await updateDoc(userRef, { approved: false, pending: false });
          console.log(`User ${relevantUser.current} denied successfully.`);
      } catch (error) {
          console.error("Error denying user:", error);
      }
  };


    const formatPhoneNumber = (phone) => {
      if (phone.length === 10) {
          return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
      return phone; // Return as-is if not 10 digits
  }


    return(
        <div style={{display:"flex",flexDirection:"column", width:"92vw"}}>
            <div style={{height: "20px"}}></div>
            <div style={{padding: 20, border: "1px solid gray", borderRadius: 2, width:"100%", backgroundColor:"white"}}>
            <div style={{height: "40px"}}></div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "center", border: "2px solid gray", borderRadius: 4}}>
                        <div style={{width: 4}} />
                        <BsSearch style={{fontSize: "1.2rem"}}/>
                        <div style={{width: 4}} />  
                        <input style={{fontSize: "1rem", border: "none", padding: 8, width: 200}} type="text" placeholder="search resident.."></input>
                    </div>
                    {/* <div style={{width: 100}}></div>
                        <div className="green-button" style={{borderRadius: 4, padding: 8, display:"flex",flexDirection:"row"}}>
                            <BsPlusCircle style={{fontSize:"1.2rem"}}/>
                            <div style={{width: 4}} />
                            <div style={{fontSize:"1rem"}}>create resident</div>
                        </div> */}
                </div>
                <div style={{height: 40}} />



  <table className="styled-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th>Email</th>
        <th>Phone #</th>
        <th style={{width:"5rem"}}></th>
      </tr>
    </thead>
    <tbody>
          {pendingResidents.length > 0 ? (
            pendingResidents.map((user) => (
              <tr key={user.id}>
                <td>{user.name || "N/A"}</td>
                <td>{user.address || "N/A"}</td>
                <td>{user.email || "N/A"}</td>
                <td>{formatPhoneNumber(user.phoneNumber) || "N/A"}</td>
                <td style={{flexDirection:"row",display:"flex", justifyContent:"space-evenly"}}>

                  <div className='clickable' onClick={() => acceptUser(user.id)}>
                    <BsCheckSquareFill size={"1.2rem"} color={"green"}/>
                  </div>
                  <div className='clickable' onClick={() => denyUserConfirm(user.id)}>
                    <BsBanFill size={"1.2rem"} color={"red"}/>
                  </div>
                  </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
                No pending requests...
              </td>
            </tr>
          )}
        </tbody>
  </table>
<div style={{height: 40}} />
  <table className="styled-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th>Email</th>
        <th>Phone #</th>
        <th style={{width:"5rem"}}></th>
      </tr>
    </thead>
    <tbody>
          {approvedResidents.length > 0 ? (
            approvedResidents.map((user) => (
              <tr key={user.id}>
                <td>{user.name || "N/A"}</td>
                <td>{user.address || "N/A"}</td>
                <td>{user.email || "N/A"}</td>
                <td>{formatPhoneNumber(user.phoneNumber) || "N/A"}</td>
                <td style={{flexDirection:"row",display:"flex", justifyContent:"space-evenly"}}>
                  {/* <div className="clickable" onClick={modifyUser(user.id)}>
                    <BsGearFill color={"blue"}/>
                  </div> */}
                  <div className='clickable' onClick={()=>removeUserConfirm(user.id)}>
                    <BsTrashFill size={"1.2rem"} color={"red"}/>
                  </div>
                  </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
                No residents found
              </td>
            </tr>
          )}
        </tbody>
  </table>


            </div>
            {popUpActive && <PopUp setPopUpActive={setPopUpActive} message={"Are you sure you want to remove this resident?"} action={removeUser} type={"confirm"}/>}
            {denyPopUpActive && <PopUp setPopUpActive={setDenyPopUpActive} message={"Are you sure you want to deny this resident?"} action={denyUser} type={"confirm"} />}
        </div>
    )
}

export default DashboardResidents