import {
    BsChatSquareQuoteFill,
    BsFillBugFill,
    BsFillExclamationTriangleFill,
} from 'react-icons/bs'

const PopUp = ({setPopUpActive, message, action, type}) => {


    const runAction = () => {
        setPopUpActive(false)
        action()
    }

    const IconSplitter = () => {
        switch(type) {
            case "confirm":
                return(<BsFillExclamationTriangleFill size={"4rem"}/>)
            case "message":
                return(<BsChatSquareQuoteFill size={"4rem"} />)
            case "error":
                return(<BsFillBugFill size={"4rem"} />)
        }
    }

    const ButtonTextSplitter = () => {
        switch(type) {
            case "confirm":
                return "Confirm"
            case "message":
                return "Okay"
            case "error":
                return "I will"
        }
    }

    return(
        <div className='pop-up-window'>
            <div className='pop-up-pane'>
                <div className='pop-up-icon'>
                    <IconSplitter />
                </div>
                <div className='pop-up-message'>
                    <div style={{fontSize:"1.2rem"}}>{message}</div>
                </div>
                <div className='pop-up-button' onClick={()=>runAction()}>
                    {ButtonTextSplitter()}
                </div>
            </div>
        </div>
    )



}

export default PopUp