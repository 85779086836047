import "./Dash.css"
import React from "react";
import {
  BsPinMapFill,
  BsFileEarmarkBarGraph,
  BsFillPersonVcardFill,
  BsPeopleFill,
  BsRocketTakeoffFill,
  BsGearWideConnected,
  BsHouseFill,
  BsBuildingFill,
  BsDoorOpenFill,
  BsClipboardDataFill,
  BsList,
  BsHouseGearFill,
  BsCashCoin,
  BsBriefcaseFill,
  BsCalendarDayFill,
  BsEnvelopeExclamationFill,
  BsKeyFill 
} from 'react-icons/bs';
import IndianHead from "../imgs/black-indian.png"
import {FBAuth} from '../Firebase/firebase'
import {signOut} from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useUserData } from "../Contexts/UserDataContext";


const Sidebar = ({setUserData,setUID,chosenPage,setChosenPage,sidebarStatus,setSidebarStatus,chosenCompany,setChosenCompany}) => {
  const navigate = useNavigate()
  const {userData} = useUserData()

  const logout = () => {
    try {
      signOut(FBAuth)
      console.log("Successfully logged out")
    } catch {
      console.error("Error logging out")
    }
    setUserData({})
    setUID("")
    navigate("/")
  }


  return(
    <div className="sidebar" style={{flex: 0.2, marginLeft: sidebarStatus?0:"-15rem"}}>
      <div className="sidebar-hamburger" onClick={()=>setSidebarStatus(!sidebarStatus)}>
        <BsList/>
      </div>
        <div className="sidebar-header">
          <img className="sidebar-header-img" src={IndianHead} alt="logo" />
        </div>
        <div className="sidebar-body">

            <>
              <div onClick={()=>setChosenPage("profile")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="profile"?"var(--indicator)":"transparent" }} />
                <BsFillPersonVcardFill style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Profile</div>
              </div>
              {/* <div onClick={()=>setChosenPage("residents")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="resident"?"var(--indicator)":"transparent" }} />
                <BsPeopleFill style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Resident</div>
              </div> */}
              <div onClick={()=>setChosenPage("payDues")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="payDues"?"var(--indicator)":"transparent" }} />
                <BsCashCoin style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Pay Dues</div>
              </div>
              <div></div>
            </>

            <div style={{height:30}}></div>
            <div style={{color:"#000"}}>Administrator:</div>
            {userData.isAdmin && (
            <>
              <div onClick={()=>setChosenPage("residences")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="residences"?"var(--indicator)":"transparent" }} />
                <BsHouseGearFill  style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Residences</div>
              </div>
              <div onClick={()=>setChosenPage("residents")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="residents"?"var(--indicator)":"transparent" }} />
                <BsPeopleFill style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Residents</div>
              </div>
              <div onClick={()=>setChosenPage("accounts")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="accounts"?"var(--indicator)":"transparent" }} />
                <BsBriefcaseFill  style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Accounts</div>
              </div>
              <div onClick={()=>setChosenPage("keyFobs")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="keyFobs"?"var(--indicator)":"transparent" }} />
                <BsKeyFill   style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Key Fobs</div>
              </div>
              <div onClick={()=>setChosenPage("editCalendar")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="editCalendar"?"var(--indicator)":"transparent" }} />
                <BsCalendarDayFill  style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Edit Calendar</div>
              </div>
              <div onClick={()=>setChosenPage("sendAlerts")} className="sidebar-item">
                <div className="sidebar-indicator" style={{backgroundColor: chosenPage=="sendAlerts"?"var(--indicator)":"transparent" }} />
                <BsEnvelopeExclamationFill  style={{fontSize: "1.2rem"}}/>
                <div className="sidebar-item-text">Send Alert</div>
              </div>
            </>
          )}




          {/* <AdminButton /> */}
        </div>
        <div className="sidebar-footer">
        <div onClick={()=>logout()} className="sidebar-item">
          <div className="sidebar-indicator" />
          <BsDoorOpenFill style={{fontSize: "1.2rem"}}/>
          <div className="sidebar-item-text">Log Out</div>
        </div>
        </div>
      </div>
    )
  }

export default Sidebar;