import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserData } from './UserDataContext';
import { collection, getDocs } from "firebase/firestore";
import { FBDataBase } from '../Firebase/firebase'; // Ensure this is correctly imported

const DashboardDataContext = createContext({});

export const useDashData = () => {
  return useContext(DashboardDataContext);
};

export const DashDataProvider = ({ children }) => {
  const { userData } = useUserData();
  const [residentsData, setResidentsData] = useState([]);
  const [residencesData, setResidencesData] = useState([]);

  useEffect(() => {
    const fetchResidents = async () => {
      if (userData?.isAdmin) {  // Ensure user is admin before fetching
        try {
          const querySnapshot = await getDocs(collection(FBDataBase, "users"));
          const usersArray = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log('users array', usersArray)
          setResidentsData(usersArray);
        } catch (error) {
          console.error("Error fetching users: ", error);
        }
      }
    };

    const fetchResidences = async() => {
      if (userData?.isAdmin) {
        try {
          const querySnapshot = await getDocs(collection(FBDataBase, "residences"));
          const residencesArray = querySnapshot.docs.map(doc=>({
            id: doc.id,
            ...doc.data()
          }))
          console.log("residences array ", residencesArray);
          const sortedResidences = [...residencesArray].sort((a, b) => {
            const streetComparison = a.name?.localeCompare(b.name);
            if (streetComparison !== 0) {
                return streetComparison; // If streets are different, sort by name
            }
            return (a.number || 0) - (b.number || 0); // If same street, sort by number
          });

          setResidencesData(sortedResidences)
        } catch(error) {
          console.error("Error fetching residences: ", error)
        }
      }

    }

    fetchResidents();
    fetchResidences();
  }, [userData]);

  return (
    <DashboardDataContext.Provider value={{ residentsData, residencesData }}>
      {children}
    </DashboardDataContext.Provider>
  );
};
