

const AddressSelect = ({addressRef}) => {
return(
  <div className='input-group'>
    <label htmlFor='address'>Select your address</label>
    <select onChange={(e)=>addressRef.current = e.target.value} style={{width: "100%"}} id='address' required >
        {/* EVENTUALLY THIS IS WHERE THE ADDRESS MAPPING GOES */}
        <option value="" disabled>
          Select an address
        </option>
        <option value="7 Erica Ln">7 Erica Ln</option>
        <option value="5 Erica Ln">5 Erica Ln</option>
    </select>
  </div>
)
}

export default AddressSelect