

import React, { useRef, useState } from 'react';

const PhoneNumberField = ({ phoneNumberRef }) => {
  const inputRefs = [useRef(), useRef(), useRef()]; // Refs for each input field
  const [values, setValues] = useState(["", "", ""]);

  // Handles changes in the input fields
  const handleChange = (e, index) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let newValues = [...values];
    newValues[index] = value;
    phoneNumberRef.current = newValues;
    setValues(newValues);

    // Move to next input if filled
    if (value.length === e.target.maxLength && index < 2) {
      inputRefs[index + 1].current.focus();
    }
  };

  // Handles Backspace to move to the previous input
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className='input-group' style={{alignItems:"center", justifyContent:"center"}}>
      <label htmlFor='phone'>Enter your phone number (optional)</label>
      <div style={{ display: 'flex', gap: '8px', justifyContent:"center", alignItems:"center" }}>
        <input
          type="text"
          maxLength="3"
          ref={inputRefs[0]}
          value={values[0]}
          onChange={(e) => handleChange(e, 0)}
          onKeyDown={(e) => handleKeyDown(e, 0)}
          style={{ width: '70px', textAlign: 'center' }}
        />
        <span>-</span>
        <input
          type="text"
          maxLength="3"
          ref={inputRefs[1]}
          value={values[1]}
          onChange={(e) => handleChange(e, 1)}
          onKeyDown={(e) => handleKeyDown(e, 1)}
          style={{ width: '70px', textAlign: 'center' }}
        />
        <span>-</span>
        <input
          type="text"
          maxLength="4"
          ref={inputRefs[2]}
          value={values[2]}
          onChange={(e) => handleChange(e, 2)}
          onKeyDown={(e) => handleKeyDown(e, 2)}
          style={{ width: '90px', textAlign: 'center' }}
        />
      </div>
    </div>
  );
};

export default PhoneNumberField;
