import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, onSnapshot, getDoc, } from "firebase/firestore";
import { FBDataBase } from '../Firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth functions


const UserDataContext = createContext({});

export const useUserData = () => {
  return useContext(UserDataContext);
}

export const UserDataProvider = ({children}) => {

  const [loading, setLoading] = useState(true);
  const [uid, setUID] = useState();
  const [userData, setUserData] = useState({
    isAdmin: false
  });

  useEffect(() => {
    const auth = getAuth(); // Initialize Firebase Auth
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const uid = user.uid;
        setUID(uid);
        // Fetch user data from Firestore
        const userDocRef = doc(FBDataBase, "users", uid);
        onSnapshot(userDocRef, (document) => {
          let newUserData = document.data()
          setUserData(newUserData);
          console.log(newUserData);
        });
      } else {
        // User is signed out
        setUID(null);
        setUserData(null);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserDataContext.Provider value={{uid, userData, loading, setUID, setUserData}}>
      {children}
    </UserDataContext.Provider>
  );
}