import { useState } from "react";

const BarSwitches = ({ relevantRef, options }) => {
  const [selected, setSelected] = useState(null);

  const handleStatusChange = (e) => {
    const status = e.target.value;
    relevantRef.current = status;
    setSelected(status);
  };

  return (
    <>
    <div style={{fontSize: "0.9rem"}}>Receive alerts?</div>
    <div className="alert-switch-container">
      {options.map((option, index) => (
        <label
          key={option.value}
          className={`alert-switch-option ${selected === option.value ? "selected" : ""}`}
        >
          <input
            type="radio"
            name="alertSwitch"
            value={option.value}
            checked={selected === option.value}
            onChange={handleStatusChange}
          />
          {option.label}
        </label>
      ))}
    </div>
    </>
  );
};

export default BarSwitches;
