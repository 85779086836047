import React, {useState} from "react";

const PayDues = () => {

    return(
        <>
            <div>PPPPPPPPay Dues</div>
        </>
    )
}

export default PayDues;