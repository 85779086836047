import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { UserDataProvider } from './Contexts/UserDataContext';
import Splash from './Splash/Splash';
import DashHandler from './Dashboard/DashHandler'
import {DashDataProvider} from './Contexts/DashboardDataContext';
import Login from './Splash/TabContent/TabLogin'

const App = () => {
  return (
    <BrowserRouter>
      <UserDataProvider>
        <DashDataProvider>
          <Routes>
            <Route path="/" element={<Splash />} />
            <Route path="/dashboard" element={<DashHandler />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </DashDataProvider>
      </UserDataProvider>
    </BrowserRouter>
  )
}

export default App