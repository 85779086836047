import {BsSearch,BsPlusCircle,BsGearFill,BsTrashFill,BsBanFill,BsCheckSquareFill } from 'react-icons/bs'
import {useDashData} from '../Contexts/DashboardDataContext';
import {useState, useRef} from 'react'
import {doc, updateDoc} from 'firebase/firestore';
import { FBDataBase } from '../Firebase/firebase';

const DashboardResidences = () => {

    const {residencesData} = useDashData()

    return(
    <div style={{display:"flex",flexDirection:"column", width:"92vw"}}>
        <div style={{height: "20px"}}></div>
        <div style={{padding: 20, border: "1px solid gray", borderRadius: 2, width:"100%", backgroundColor:"white"}}>
        <div style={{height: "40px"}}></div>

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                <div style={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "center", border: "2px solid gray", borderRadius: 4}}>
                    <div style={{width: 4}} />
                    <BsSearch style={{fontSize: "1.2rem"}}/>
                    <div style={{width: 4}} />  
                    <input style={{fontSize: "1rem", border: "none", padding: 8, width: 200}} type="text" placeholder="search residences.."></input>
                </div>
                {/* <div style={{width: 100}}></div>
                    <div className="green-button" style={{borderRadius: 4, padding: 8, display:"flex",flexDirection:"row"}}>
                        <BsPlusCircle style={{fontSize:"1.2rem"}}/>
                        <div style={{width: 4}} />
                        <div style={{fontSize:"1rem"}}>create resident</div>
                    </div> */}
            </div>
            <div style={{height: 40}} />



    <table className="styled-table">
        <thead>
        <tr>
            <th>#</th>
            <th>Street</th>
            <th>Type</th>
            <th>Status</th>
            <th>Note</th>
            <th style={{width:"5rem"}}></th>
        </tr>
        </thead>
        <tbody>
            {residencesData.length > 0 ? (
                residencesData.map((residence) => (
                <tr key={residence.id}>
                    <td>{residence.number || "N/A"}</td>
                    <td>{(residence.name + " " + residence.street) || "N/A"}</td>
                    <td>{residence.type || "N/A"}</td>
                    <td>{residence.status || "N/A"}</td>
                    <td>{residence.note || "N/A"}</td>
                    <td style={{flexDirection:"row",display:"flex", justifyContent:"space-evenly"}}>
                    <div className="clickable" onClick={()=>console.log(("hi"))}>
                        <BsGearFill color={"blue"}/>
                    </div>

                    </td>
                </tr>
                ))
            ) : (
                <tr>
                <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
                    No residences found
                </td>
                </tr>
            )}
            </tbody>
    </table>
    </div>
  </div>
    )
}

export default DashboardResidences